import { fullUrl } from 'scripts/fullUrl'
import { ApproveAccessRequestInstancesResponse } from 'types/ApiResponse'
import { axiosClient } from 'scripts/api'

export const putAccessRequestInstanceApprove = async (params: {
  sessionToken: string
  id: string
  user_ip: string
  comment?: string | null
}): Promise<ApproveAccessRequestInstancesResponse> => {
  const res = await axiosClient.put<ApproveAccessRequestInstancesResponse>(
    `${fullUrl('api')}/access_request/access_request_instances/${
      params.id
    }/approve`,
    params,
  )
  return res.data
}
