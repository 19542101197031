import React, {
  useState,
  useEffect,
  useCallback,
  useReducer,
  useMemo,
} from 'react'
import { cookies } from 'scripts/cookies'
import {
  Box,
  Button,
  Paper,
  Tab,
  Tabs,
  Divider,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  CircularProgress,
  SxProps,
  IconButton,
  Tooltip,
  Card,
  CardHeader,
  Avatar,
} from '@mui/material'
import { Edit, ZoomIn, Publish } from '@mui/icons-material'
import { UserInfoPart } from 'presentation/pages/apps/Common/Header/Large/UserInfoPart/userInfo'
import { LogoPartForAccessRequestApprove } from 'presentation/pages/apps/Common/Header/Large/LogoPartForAccessRequestApprove'
import { HeaderSmallWithAccessRequestApprove } from 'presentation/pages/apps/Common/Header/Small/headerWithAccessRequestApprove'
import { getAccessRequestInstances } from 'api/getAccessRequestInstances'
import { putAccessRequestInstanceApprove } from 'api/putAccessRequestInstanceApprove'
import { putAccessRequestInstanceReject } from 'api/putAccessRequestInstanceReject'
import { useSnackbar } from 'presentation/components/Snackbar/useSnackbar'
import { navigate } from 'gatsby'
import {
  AccessProfile,
  AccessRequestInstance,
  AccessRole,
  AppEntitlementSchema,
  Approval,
  Entitlement,
  Resource,
  ResourceApp,
} from 'types/ApiResponse'
import { useIpAddress } from 'api/useIpAddress'
import { getEnduser } from 'api/getEnduser'
import { LARGE_APP_LIST_MAX_WIDTH } from '../apps/AppExists/Large/AppList'
import {
  DataGridPremium,
  GRID_ACTIONS_COLUMN_TYPE,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_CHECKBOX_SELECTION_FIELD,
  GridColumns,
  GridOverlay,
  GridSelectionModel,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  jaJP,
} from '@mui/x-data-grid-premium'
import { Helmet } from 'react-helmet'
import { getAccessRoles } from 'api/getAccessRoles'
import { getAccessProfiles } from 'api/getAccessProfiles'
import { getEntitlements } from 'api/getEntitlements'
import { getResources } from 'api/getResources'
import { getResourceApps } from 'api/getResourceApps'
import { getAppEntitlementSchemas } from 'api/getAppEntitlementSchemas'

// TODO: useTranslation()を使って多言語化する

const tabTypes = {
  REQUESTED_TO_ACTION: 'requested_to_action',
  REQUESTED_TO: 'requested_to',
} as const

interface Action {
  type: keyof typeof ACTION_TYPE
  payload: Partial<State>
}

const ACTION_TYPE = {
  initialize: 'initialize',
  changeTabType: 'changeTabType',
  changeSelectedRequestedToActionAccessRequestInstanceIds:
    'changeSelectedRequestedToActionAccessRequestInstanceIds',
  openDetailAccessRequestInstanceDialog:
    'openDetailAccessRequestInstanceDialog',
  closeDetailAccessRequestInstanceDialog:
    'closeDetailAccessRequestInstanceDialog',
  changeComment: 'changeComment',
  openApproveAccesRequestDialog: 'openApproveAccesRequestDialog',
  closeApproveAccesRequestDialog: 'closeApproveAccesRequestDialog',
  approveAccessRequest: 'approveAccessRequest',
  openRejectAccessRequestDialog: 'openRejectAccessRequestDialog',
  closeRejectAccessRequestDialog: 'closeRejectAccessRequestDialog',
  rejectAccessRequest: 'rejectAccessRequest',
} as const

const reducer = (state: State, action: Action): State => {
  return {
    ...state,
    ...action.payload,
  }
}

const initialState: State = {
  windowWidth: undefined,
  tabType: tabTypes.REQUESTED_TO_ACTION,
  sessionToken: '',
  teamName: '',
  userName: '',
  selectedRequestedToActionAccessRequestInstanceIds: [],
  detailAccessRequestInstance: undefined,
  comment: '',
  requestedToActionAccessRequestInstances: [],
  requestedToAccessRequestInstances: [],
  accessRoles: [],
  accessProfiles: [],
  entitlements: [],
  appEntitlementSchemas: [],
  resourceApps: [],
  resources: [],
  openDetailAccessRequestInstanceDialog: false,
  openApproveAccesRequestDialog: false,
  openRejectAccessRequestDialog: false,
}

interface State {
  windowWidth: number | undefined
  tabType: typeof tabTypes[keyof typeof tabTypes]
  sessionToken: string
  teamName: string
  userName: string
  selectedRequestedToActionAccessRequestInstanceIds: GridSelectionModel
  detailAccessRequestInstance: AccessRequestInstance | undefined
  comment: string
  requestedToActionAccessRequestInstances: AccessRequestInstance[]
  requestedToAccessRequestInstances: AccessRequestInstance[]
  accessRoles: AccessRole[]
  accessProfiles: AccessProfile[]
  entitlements: Entitlement[]
  appEntitlementSchemas: AppEntitlementSchema[]
  resourceApps: ResourceApp[]
  resources: Resource[]
  openDetailAccessRequestInstanceDialog: boolean
  openApproveAccesRequestDialog: boolean
  openRejectAccessRequestDialog: boolean
}

// TODO: 以下constants, scriptsは 'page/accessRequest' と共通化できる
// dataGridSxProps
// translateErrorMessageはactionを合わせることで共通化できる
// formatDateTime
// translateStatus
// translateStatusColor
// translateApprovalStatus
// translateApprovalStatusColor
// getApprovalsProgress

const dataGridSxProps: SxProps = {
  border: 'none',
  '& .MuiDataGrid-main': {
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.17)',
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#e5e5e5',
  },
  '& .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-pinnedColumnHeaders': {
    backgroundColor: '#e5e5e5',
  },
} as const

const translateErrorMessage = (
  reason: string | undefined,
  action: 'get' | 'approve' | 'reject',
) =>
  ({
    get:
      reason === 'does not have locked_das_user_id'
        ? '申請や承認を行うためには、アカウントの紐づけが必要です。詳しくは管理者にご確認ください'
        : reason === 'cannot request access role' ||
          reason === 'cannot request access profile' ||
          reason === 'cannot request entitlement'
        ? '申請や承認の機能を利用することができません。詳しくは管理者にご確認ください'
        : 'データの取得に失敗しました。サポート担当までご連絡ください',
    approve:
      reason === 'does not have locked_das_user_id'
        ? '申請や承認を行うためには、アカウントの紐づけが必要です。詳しくは管理者にご確認ください'
        : reason === 'enduser_binding not found'
        ? '承認に成功しましたが、メールでの通知に失敗しました。詳しくは管理者にご確認ください'
        : reason === 'requested enduser not found'
        ? '承認に成功しましたが、メールでの通知に失敗しました。詳しくは管理者にご確認ください'
        : reason === 'bad input: cannot find in_review status approval'
        ? '承認待ちステータスの承認ステップが見つかりませんでした。サポート担当までご連絡ください。'
        : reason ===
          'bad input: cannot execute approval action, because approver is not in approvers'
        ? '承認者として承認ステップに指定されていないため、承認が行えませんでした。サポート担当までご連絡ください。'
        : reason ===
          'bad input: cannot execute approval action, because approver has already execute approval action'
        ? 'すでに承認者として承認または却下を行っているため、承認が行えませんでした。サポート担当までご連絡ください。'
        : reason === 'bad input: approver does not exist in approvers'
        ? '承認者として承認ステップに指定されていないため、承認が行えませんでした。サポート担当までご連絡ください。'
        : '承認に失敗しました。サポート担当までご連絡ください',
    reject:
      reason === 'does not have locked_das_user_id'
        ? '申請や承認を行うためには、アカウントの紐づけが必要です。詳しくは管理者にご確認ください'
        : reason === 'enduser_binding not found'
        ? '却下に成功しましたが、メールでの通知に失敗しました。詳しくは管理者にご確認ください'
        : reason === 'requested enduser not found'
        ? '却下に成功しましたが、メールでの通知に失敗しました。詳しくは管理者にご確認ください'
        : reason === 'bad input: cannot find in_review status approval'
        ? '承認待ちステータスの承認ステップが見つかりませんでした。サポート担当までご連絡ください。'
        : reason ===
          'bad input: cannot execute approval action, because approver is not in approvers'
        ? '承認者として承認ステップに指定されていないため、却下が行えませんでした。サポート担当までご連絡ください。'
        : reason ===
          'bad input: cannot execute approval action, because approver has already execute approval action'
        ? 'すでに承認者として承認または却下を行っているため、却下が行えませんでした。サポート担当までご連絡ください。'
        : reason === 'bad input: approver does not exist in approvers'
        ? '承認者として承認ステップに指定されていないため、却下が行えませんでした。サポート担当までご連絡ください。'
        : '却下に失敗しました。サポート担当までご連絡ください',
  }[action])

const formatDateTime = (isoString: string) => {
  const date = new Date(isoString)
  const year = date.getFullYear()
  const month = date.getMonth() + 1 // getMonth() は0から始まるため、+1 する
  const day = date.getDate()
  const hours = date.getHours()
  const minutes = ('0' + date.getMinutes()).slice(-2) // 分が1桁の場合は0を追加

  return `${year}年${month}月${day}日 ${hours}時${minutes}分`
}

const translateStatus = (status: AccessRequestInstance['status']) =>
  status === 'in_review'
    ? '承認待ち'
    : status === 'rejected'
    ? '却下'
    : status === 'approved'
    ? '承認済み'
    : status === 'cancelled'
    ? '取り下げ'
    : status === 'terminated'
    ? '強制却下'
    : '不明'

const translateStatusColor = (status: AccessRequestInstance['status']) =>
  status === 'in_review'
    ? '#0288d1'
    : status === 'rejected'
    ? '#d32f2f'
    : status === 'approved'
    ? '#2e7d32'
    : status === 'cancelled'
    ? 'gray'
    : status === 'terminated'
    ? 'gray'
    : 'gray'

const translateApprovalStatus = (status: Approval['status']) =>
  status === 'in_review'
    ? '承認待ち'
    : status === 'rejected'
    ? '却下'
    : status === 'approved'
    ? '承認済み'
    : status === 'pending'
    ? '---'
    : '不明'

const translateApprovalStatusColor = (status: Approval['status']) =>
  status === 'in_review'
    ? '#0288d1'
    : status === 'rejected'
    ? '#d32f2f'
    : status === 'approved'
    ? '#2e7d32'
    : status === 'pending'
    ? 'inherit'
    : 'gray'

const getApprovalsProgress = (approvals: Approval[]) => {
  const totalApprovalsCount = approvals.length
  const inProgressApprovalsCount = approvals.filter(
    (approval) =>
      approval.status === 'in_review' ||
      approval.status === 'approved' ||
      approval.status === 'rejected',
  ).length

  return `${inProgressApprovalsCount} / ${totalApprovalsCount}`
}

const accessRequestInstanceGridColumns = ({
  checkboxColumn,
  gridActionItems,
}: {
  checkboxColumn?: typeof GRID_CHECKBOX_SELECTION_COL_DEF
  gridActionItems?: (row: AccessRequestInstance) => JSX.Element[]
}): GridColumns<AccessRequestInstance> => {
  const columns: GridColumns<AccessRequestInstance> = [
    {
      type: 'dateTime',
      field: 'started_at',
      headerName: '申請日時',
      width: 200,
      renderCell: ({ row }) => (
        <Typography variant="body2">
          {row.started_at !== null ? formatDateTime(row.started_at) : '---'}
        </Typography>
      ),
      getApplyQuickFilterFn: (filterValue: string) => {
        if (!filterValue) return null
        return ({ value }): boolean => {
          if (value === null) return false
          return formatDateTime(value).includes(filterValue)
        }
      },
    },
    {
      type: 'singleSelect',
      valueOptions: ['承認待ち', '却下', '承認済み', '取り下げ', '強制却下'],
      field: 'status',
      headerName: 'ステータス',
      width: 200,
      valueGetter: ({ row }) => translateStatus(row.status),
      renderCell: ({ row, value }) => (
        <Typography variant="body2" color={translateStatusColor(row.status)}>
          {`${value} (${getApprovalsProgress(row.approvals)})`}
        </Typography>
      ),
      getApplyQuickFilterFn: (filterValue: string) => {
        if (!filterValue) return null
        return ({ row, value }): boolean => {
          if (value === null) return false
          const statusValue = `${value} (${getApprovalsProgress(
            row.approvals,
          )})`
          return statusValue.includes(filterValue)
        }
      },
    },
    {
      type: 'string',
      field: 'requested_object.name',
      headerName: '権限',
      width: 300,
      valueGetter: ({ row }) =>
        row.requested_object.name === '' ? null : row.requested_object.name,
      renderCell: ({ value }) => (
        <Tooltip title={value ?? '---'} arrow>
          <Box
            data-testid="connection-description-cell"
            textOverflow={'ellipsis'}
            overflow="hidden"
          >
            {value ?? '---'}
          </Box>
        </Tooltip>
      ),
    },
    {
      type: 'string',
      field: 'requested_by.name',
      headerName: '申請者',
      width: 200,
      valueGetter: ({ row }) =>
        row.requested_by.name === '' ? null : row.requested_by.name,
      renderCell: ({ value }) => (
        <Tooltip title={value ?? '---'} arrow>
          <Box
            data-testid="connection-description-cell"
            textOverflow={'ellipsis'}
            overflow="hidden"
          >
            {value ?? '---'}
          </Box>
        </Tooltip>
      ),
    },
    {
      type: 'string',
      field: 'requested_by.comment',
      headerName: 'コメント',
      width: 200,
      valueGetter: ({ row }) =>
        row.requested_by.comment === '' ? null : row.requested_by.comment,
      renderCell: ({ value }) => (
        <Tooltip title={value ?? '---'} arrow>
          <Box
            data-testid="connection-description-cell"
            textOverflow={'ellipsis'}
            overflow="hidden"
          >
            {value ?? '---'}
          </Box>
        </Tooltip>
      ),
    },
    {
      type: 'dateTime',
      field: 'expired_at',
      headerName: '有効期限',
      width: 200,
      renderCell: ({ row }) => (
        <Typography variant="body2">
          {row.expired_at !== null ? formatDateTime(row.expired_at) : '---'}
        </Typography>
      ),
      getApplyQuickFilterFn: (filterValue: string) => {
        if (!filterValue) return null
        return ({ value }): boolean => {
          if (value === null) return false
          return formatDateTime(value).includes(filterValue)
        }
      },
    },
    {
      type: 'string',
      field: 'id',
      headerName: '申請ID',
      width: 300,
      valueGetter: ({ row }) => (row.id === '' ? null : row.id),
      renderCell: ({ value }) => (
        <Tooltip title={value ?? '---'} arrow>
          <Box
            data-testid="connection-description-cell"
            textOverflow={'ellipsis'}
            overflow="hidden"
          >
            {value ?? '---'}
          </Box>
        </Tooltip>
      ),
    },
  ]
  if (checkboxColumn !== undefined) {
    columns.unshift(checkboxColumn)
  }
  if (gridActionItems !== undefined) {
    columns.push({
      field: 'actions',
      type: 'actions',
      headerName: '詳細',
      width: 50,
      cellClassName: 'actions',
      disableColumnMenu: true,
      getActions: ({ row }) => gridActionItems(row),
    })
  }
  return columns
}

export const AccessRequestApprove = (): JSX.Element => {
  const [openSnackbar] = useSnackbar()
  const [{ ipAddress }] = useIpAddress()
  const [state, dispatch] = useReducer(reducer, initialState)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)

      // TODO: MSO利用がないテナントを用意する必要が発生した場合に、テナント特定のためにアプリ一覧で行っているsubdomainの取得処理を追加
      // const subdomain = getSubdomain(window.location.host)

      const sessionToken = cookies.get('session_token')
      if (!sessionToken) {
        navigate('/login', { replace: true })
        return
      }
      const enduserResponse = await getEnduser()
      if (enduserResponse.status !== 200) {
        navigate('/login', { replace: true })
        return
      }
      const { first_name, middle_name, last_name } =
        enduserResponse.data.enduser
      const userName = `${last_name} ${middle_name ?? ''} ${first_name}`

      // 初期レンダリング時はタブがREQUESTED_TO_ACTIONのため、承認待ちの申請一覧のみを取得する
      try {
        const responses = await Promise.all([
          getAccessRequestInstances({
            sessionToken,
            type: 'requested_to_action',
          }),
          getAccessRequestInstances({
            sessionToken,
            type: 'requested_to',
          }),
          getAccessRoles({ sessionToken }),
          getAccessProfiles({ sessionToken }),
          getEntitlements({ sessionToken }),
          getAppEntitlementSchemas({ sessionToken }),
          getResources({ sessionToken }),
          getResourceApps({ sessionToken }),
        ])
        if (responses.some((r) => r.status !== 200)) {
          if (
            responses.find((r) => r.status !== 200)?.reason ===
            'could not find enduser'
          ) {
            navigate('/login', { replace: true })
            return
          }
          openSnackbar(
            translateErrorMessage(
              responses.find((r) => r.status !== 200)?.reason,
              'get',
            ),
          )
          return
        }
        const [
          requestedToActionAccessRequestInstancesRes,
          requestedToAccessRequestInstancesRes,
          accessRolesRes,
          accessProfilesRes,
          entitlementsRes,
          appEntitlementSchemasRes,
          resourcesRes,
          resourceAppsRes,
        ] = responses
        dispatch({
          type: ACTION_TYPE.initialize,
          payload: {
            windowWidth: window?.innerWidth,
            sessionToken,
            teamName: enduserResponse.data.enduser.team_name,
            userName,
            requestedToActionAccessRequestInstances: [
              ...requestedToActionAccessRequestInstancesRes.instances,
            ].reverse(),
            requestedToAccessRequestInstances: [
              ...requestedToAccessRequestInstancesRes.instances,
            ].reverse(),
            accessRoles: accessRolesRes.access_roles,
            accessProfiles: accessProfilesRes.access_profiles,
            entitlements: entitlementsRes.entitlements,
            appEntitlementSchemas:
              appEntitlementSchemasRes.app_entitlement_schemas,
            resources: resourcesRes.resources,
            resourceApps: resourceAppsRes.apps,
          },
        })
      } catch (error) {
        openSnackbar(
          'システムエラーが発生しました。サポート担当までご連絡ください。',
        )
        throw error
      }

      // TODO: MSO利用がないテナントを用意する必要が発生した場合に、テナント特定のためにアプリ一覧で行っているsubdomainの取得処理を追加
      // // ログインできたら、subdomainを記録
      // if (subdomain) {
      //   cookies.setSessionTokenToCookie(sessionToken, 123)
      //   cookies.setAuthSubdomainToCookie(subdomain)
      // }

      setIsLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeTab = useCallback(
    (
      event: React.SyntheticEvent,
      newValue: typeof tabTypes[keyof typeof tabTypes],
    ) =>
      dispatch({
        type: ACTION_TYPE.changeTabType,
        payload: {
          tabType: newValue,
        },
      }),
    [],
  )

  const handleChangeSelectedRequestedToActionAccessRequestInstanceIds =
    useCallback((selectionModel: GridSelectionModel) => {
      dispatch({
        type: ACTION_TYPE.changeSelectedRequestedToActionAccessRequestInstanceIds,
        payload: {
          selectedRequestedToActionAccessRequestInstanceIds: selectionModel,
        },
      })
    }, [])

  const handleOpenDetailAccessRequestInstanceDialog = (
    row: AccessRequestInstance,
  ) => {
    dispatch({
      type: ACTION_TYPE.openDetailAccessRequestInstanceDialog,
      payload: {
        detailAccessRequestInstance: row,
        openDetailAccessRequestInstanceDialog: true,
      },
    })
  }

  const handleCloseDetailAccessRequestInstanceDialog = () => {
    dispatch({
      type: ACTION_TYPE.closeDetailAccessRequestInstanceDialog,
      payload: {
        detailAccessRequestInstance: undefined,
        openDetailAccessRequestInstanceDialog: false,
      },
    })
  }

  const handleOpenApproveAccessRequestDialog = () => {
    dispatch({
      type: ACTION_TYPE.openApproveAccesRequestDialog,
      payload: {
        openApproveAccesRequestDialog: true,
      },
    })
  }

  const handleCloseApproveAccessRequestDialog = () => {
    dispatch({
      type: ACTION_TYPE.closeApproveAccesRequestDialog,
      payload: {
        openApproveAccesRequestDialog: false,
        comment: '',
      },
    })
  }

  const handleOpenRejectAccessRequestDialog = () => {
    dispatch({
      type: ACTION_TYPE.openRejectAccessRequestDialog,
      payload: {
        openRejectAccessRequestDialog: true,
      },
    })
  }

  const handleCloseRejectAccessRequestDialog = () => {
    dispatch({
      type: ACTION_TYPE.closeRejectAccessRequestDialog,
      payload: {
        openRejectAccessRequestDialog: false,
        comment: '',
      },
    })
  }

  const handleApproveAccessRequest = useCallback(async () => {
    try {
      const responses = await Promise.all(
        state.selectedRequestedToActionAccessRequestInstanceIds.map((id) =>
          putAccessRequestInstanceApprove({
            sessionToken: state.sessionToken,
            user_ip: ipAddress || '',
            comment: state.comment,
            id: id.toString(),
          }),
        ),
      )
      if (responses.some((r) => r.status !== 200)) {
        if (responses.some((r) => r.reason === 'could not find enduser')) {
          navigate('/login', { replace: true })
          return
        }
        dispatch({
          type: ACTION_TYPE.approveAccessRequest,
          payload: {
            openApproveAccesRequestDialog: false,
            comment: '',
            selectedRequestedToActionAccessRequestInstanceIds: [],
          },
        })
        openSnackbar(
          translateErrorMessage(
            responses.find((r) => r.status !== 200)?.reason,
            'approve',
          ),
        )
        return
      }

      const accessRequestInstanceResponses = await Promise.all([
        getAccessRequestInstances({
          sessionToken: state.sessionToken,
          type: 'requested_to_action',
        }),
        getAccessRequestInstances({
          sessionToken: state.sessionToken,
          type: 'requested_to',
        }),
      ])
      if (accessRequestInstanceResponses.some((r) => r.status !== 200)) {
        if (
          accessRequestInstanceResponses.find((r) => r.status !== 200)
            ?.reason === 'could not find enduser'
        ) {
          navigate('/login', { replace: true })
          return
        }
        dispatch({
          type: ACTION_TYPE.approveAccessRequest,
          payload: {
            openApproveAccesRequestDialog: false,
            comment: '',
            selectedRequestedToActionAccessRequestInstanceIds: [],
          },
        })
        openSnackbar(
          translateErrorMessage(
            accessRequestInstanceResponses.find((r) => r.status !== 200)
              ?.reason,
            'get',
          ),
        )
        return
      }
      const [
        requestedToActionAccessRequestInstancesRes,
        requestedToAccessRequestInstancesRes,
      ] = accessRequestInstanceResponses
      dispatch({
        type: ACTION_TYPE.approveAccessRequest,
        payload: {
          openApproveAccesRequestDialog: false,
          comment: '',
          selectedRequestedToActionAccessRequestInstanceIds: [],
          requestedToActionAccessRequestInstances: [
            ...requestedToActionAccessRequestInstancesRes.instances,
          ].reverse(),
          requestedToAccessRequestInstances: [
            ...requestedToAccessRequestInstancesRes.instances,
          ].reverse(),
        },
      })
      openSnackbar('承認しました')
    } catch (error) {
      dispatch({
        type: ACTION_TYPE.approveAccessRequest,
        payload: {
          openApproveAccesRequestDialog: false,
          comment: '',
          selectedRequestedToActionAccessRequestInstanceIds: [],
        },
      })
      openSnackbar(
        'システムエラーが発生しました。サポート担当までご連絡ください。',
      )
      throw error
    }
  }, [
    openSnackbar,
    state.selectedRequestedToActionAccessRequestInstanceIds,
    state.sessionToken,
    state.comment,
    ipAddress,
  ])

  const handleRejectAccessRequest = useCallback(async () => {
    try {
      const responses = await Promise.all(
        state.selectedRequestedToActionAccessRequestInstanceIds.map((id) =>
          putAccessRequestInstanceReject({
            sessionToken: state.sessionToken,
            user_ip: ipAddress || '',
            comment: state.comment,
            id: id.toString(),
          }),
        ),
      )
      if (responses.some((r) => r.status !== 200)) {
        if (responses.some((r) => r.reason === 'could not find enduser')) {
          navigate('/login', { replace: true })
          return
        }
        dispatch({
          type: ACTION_TYPE.rejectAccessRequest,
          payload: {
            openRejectAccessRequestDialog: false,
            comment: '',
            selectedRequestedToActionAccessRequestInstanceIds: [],
          },
        })
        openSnackbar(
          translateErrorMessage(
            responses.find((r) => r.status !== 200)?.reason,
            'reject',
          ),
        )
        return
      }

      const accessRequestInstanceResponses = await Promise.all([
        getAccessRequestInstances({
          sessionToken: state.sessionToken,
          type: 'requested_to_action',
        }),
        getAccessRequestInstances({
          sessionToken: state.sessionToken,
          type: 'requested_to',
        }),
      ])
      if (accessRequestInstanceResponses.some((r) => r.status !== 200)) {
        if (
          accessRequestInstanceResponses.find((r) => r.status !== 200)
            ?.reason === 'could not find enduser'
        ) {
          navigate('/login', { replace: true })
          return
        }
        dispatch({
          type: ACTION_TYPE.rejectAccessRequest,
          payload: {
            openRejectAccessRequestDialog: false,
            comment: '',
            selectedRequestedToActionAccessRequestInstanceIds: [],
          },
        })
        openSnackbar(
          translateErrorMessage(
            accessRequestInstanceResponses.find((r) => r.status !== 200)
              ?.reason,
            'get',
          ),
        )
        return
      }
      const [
        requestedToActionAccessRequestInstancesRes,
        requestedToAccessRequestInstancesRes,
      ] = accessRequestInstanceResponses
      dispatch({
        type: ACTION_TYPE.rejectAccessRequest,
        payload: {
          openRejectAccessRequestDialog: false,
          comment: '',
          selectedRequestedToActionAccessRequestInstanceIds: [],
          requestedToActionAccessRequestInstances: [
            ...requestedToActionAccessRequestInstancesRes.instances,
          ].reverse(),
          requestedToAccessRequestInstances: [
            ...requestedToAccessRequestInstancesRes.instances,
          ].reverse(),
        },
      })
      openSnackbar('却下しました')
    } catch (error) {
      dispatch({
        type: ACTION_TYPE.rejectAccessRequest,
        payload: {
          openRejectAccessRequestDialog: false,
          comment: '',
          selectedRequestedToActionAccessRequestInstanceIds: [],
        },
      })
      openSnackbar(
        'システムエラーが発生しました。サポート担当までご連絡ください。',
      )
      throw error
    }
  }, [
    openSnackbar,
    state.selectedRequestedToActionAccessRequestInstanceIds,
    state.sessionToken,
    state.comment,
    ipAddress,
  ])

  const handleClickApproveAccessRequest = useCallback(async () => {
    setIsLoading(true)
    await handleApproveAccessRequest()
    setIsLoading(false)
  }, [handleApproveAccessRequest])

  const handleClickRejectAccessRequest = useCallback(async () => {
    setIsLoading(true)
    await handleRejectAccessRequest()
    setIsLoading(false)
  }, [handleRejectAccessRequest])

  const requestedToActionAccessRequestInstanceColumns = useMemo(
    () =>
      accessRequestInstanceGridColumns({
        checkboxColumn: {
          ...GRID_CHECKBOX_SELECTION_COL_DEF,
          filterable: true,
          hideable: false,
        },
        gridActionItems: (row) => [
          <IconButton
            className="focus:outline-none"
            onClick={() => handleOpenDetailAccessRequestInstanceDialog(row)}
          >
            <ZoomIn />
          </IconButton>,
        ],
      }),
    [],
  )

  const requestedToAccessRequestInstanceColumns = useMemo(
    () =>
      accessRequestInstanceGridColumns({
        gridActionItems: (row) => [
          <IconButton
            className="focus:outline-none"
            onClick={() => handleOpenDetailAccessRequestInstanceDialog(row)}
          >
            <ZoomIn />
          </IconButton>,
        ],
      }),
    [],
  )

  interface detailRequestedObject {
    logoImageUrl: string | undefined
    title: string
    subheader: string
  }

  const detailRequestedObject: detailRequestedObject | undefined =
    useMemo(() => {
      if (
        state.detailAccessRequestInstance?.requested_object.type ===
        'access_role'
      ) {
        const accessRole = state.accessRoles.find(
          (role) =>
            role.id === state.detailAccessRequestInstance?.requested_object.id,
        )
        return {
          logoImageUrl: undefined,
          title: '権限ロール',
          subheader: `${accessRole?.name ?? '---'}`,
        }
      } else if (
        state.detailAccessRequestInstance?.requested_object.type ===
        'access_profile'
      ) {
        const accessProfile = state.accessProfiles.find(
          (profile) =>
            profile.id ===
            state.detailAccessRequestInstance?.requested_object.id,
        )
        const resource = state.resources.find(
          (r) => r.id === accessProfile?.resource_id,
        )
        const app = state.resourceApps.find((a) => a.id === resource?.app_id)
        const titleFirst = '権限ポリシー'
        const titleSecond =
          resource !== undefined ? `> ${resource.name}` : '> ---'
        return {
          logoImageUrl: app?.logo_image_url,
          title: `${titleFirst} ${titleSecond}`,
          subheader: accessProfile?.name ?? '---',
        }
      } else if (
        state.detailAccessRequestInstance?.requested_object.type ===
        'entitlement'
      ) {
        const entitlement = state.entitlements.find(
          (entitlement) =>
            entitlement.id ===
            state.detailAccessRequestInstance?.requested_object.id,
        )
        const resource = state.resources.find(
          (r) => r.id === entitlement?.resource_id,
        )
        const app = state.resourceApps.find((a) => a.id === resource?.app_id)
        const schema = state.appEntitlementSchemas
          .find((aes) => aes.app_id === resource?.app_id)
          ?.attribute_schemas.find(
            (as) => as.object_type === entitlement?.object_type,
          )
        const titleFirst = '権限'
        const titleSecond =
          resource !== undefined ? `> ${resource.name}` : '> ---'
        const titleThird = schema !== undefined ? `> ${schema.label}` : '> ---'
        return {
          logoImageUrl: app?.logo_image_url,
          title: `${titleFirst} ${titleSecond} ${titleThird}`,
          subheader: entitlement?.label ?? '---',
        }
      } else {
        return undefined
      }
    }, [
      state.detailAccessRequestInstance,
      state.accessRoles,
      state.accessProfiles,
      state.entitlements,
      state.resources,
      state.resourceApps,
      state.appEntitlementSchemas,
    ])

  return (
    <>
      <Helmet>
        <title data-test="accessRequestApprovePage">LOCKED マイページ</title>
      </Helmet>
      <div className="relative min-h-screen" style={{ background: '#E5E5E5' }}>
        {state.windowWidth && state.windowWidth < 1000 ? (
          <HeaderSmallWithAccessRequestApprove />
        ) : (
          <div className="fixed w-full" style={{ zIndex: 1 }}>
            <UserInfoPart
              teamName={state.teamName}
              userName={state.userName}
            ></UserInfoPart>
            <LogoPartForAccessRequestApprove />
          </div>
        )}
        <div
          className="w-full"
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
            height: 150,
            paddingBottom: 5,
          }}
        ></div>
        <div className="mx-auto" style={{ maxWidth: LARGE_APP_LIST_MAX_WIDTH }}>
          <Box display="flex" marginBottom={2}>
            <div
              className="flex pl-4 items-center"
              style={{
                width: '100%',
                height: 32,
              }}
            >
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 5.5H5V0.5H0V5.5ZM7.5 20.5H12.5V15.5H7.5V20.5ZM0 20.5H5V15.5H0V20.5ZM0 13H5V8H0V13ZM7.5 13H12.5V8H7.5V13ZM15 0.5V5.5H20V0.5H15ZM7.5 5.5H12.5V0.5H7.5V5.5ZM15 13H20V8H15V13ZM15 20.5H20V15.5H15V20.5Z"
                  fill="#2460D1"
                />
              </svg>
              <span
                className="ml-2 text-lg"
                style={{ fontWeight: 700, color: '#4F4F4F' }}
              >
                権限の承認
              </span>
            </div>
          </Box>
          <div
            style={{
              width: '100%',
              display: 'grid',
              columnGap: 10,
              rowGap: 10,
            }}
          ></div>
          <Box position="relative" boxShadow="0px 2px 2px rgba(0, 0, 0, 0.17)">
            <Tabs
              value={state.tabType}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChangeTab}
              sx={{ backgroundColor: 'white' }} // この行を使用してスタイルを適用
            >
              <Tab
                value={tabTypes.REQUESTED_TO_ACTION}
                label={
                  <Box display="flex" alignItems="center">
                    <Publish />
                    <Box ml={1}>承認待ちの申請一覧</Box>
                  </Box>
                }
                className="focus:outline-none"
              />
              <Tab
                value={tabTypes.REQUESTED_TO}
                label={
                  <Box display="flex" alignItems="center">
                    <Edit />
                    <Box ml={1}>申請された履歴の一覧</Box>
                  </Box>
                }
                className="focus:outline-none"
              />
            </Tabs>
            <Divider />
            {state.tabType === tabTypes.REQUESTED_TO_ACTION && (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="end"
                p={2}
                style={{ background: 'white' }}
              >
                <Box
                  sx={{
                    height: '50vh',
                    width: '100%',
                  }}
                >
                  <DataGridPremium
                    loading={isLoading}
                    disableRowGrouping
                    disableColumnMenu
                    checkboxSelection
                    selectionModel={
                      state.selectedRequestedToActionAccessRequestInstanceIds
                    }
                    onSelectionModelChange={
                      handleChangeSelectedRequestedToActionAccessRequestInstanceIds
                    }
                    localeText={
                      jaJP.components.MuiDataGrid.defaultProps.localeText
                    }
                    rows={state.requestedToActionAccessRequestInstances}
                    columns={requestedToActionAccessRequestInstanceColumns}
                    hideFooter
                    components={{
                      Toolbar: (props) => (
                        <>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            sx={{ flexGrow: 1, textAlign: 'left' }} // 左寄せで表示
                          >
                            対象の申請を選択してください。
                          </Typography>
                          <GridToolbarContainer
                            {...props}
                            sx={{ pt: 2, pb: 2 }}
                          >
                            <GridToolbarQuickFilter />
                            <Box flex={1} />
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={
                                state
                                  .selectedRequestedToActionAccessRequestInstanceIds
                                  .length === 0
                              }
                              onClick={handleOpenApproveAccessRequestDialog}
                              sx={{ marginLeft: 'auto', marginRight: '12px' }} // 右寄せで表示
                            >
                              {state
                                .selectedRequestedToActionAccessRequestInstanceIds
                                .length === 0
                                ? '承認する'
                                : `${state.selectedRequestedToActionAccessRequestInstanceIds.length}件を承認する`}
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              disabled={
                                state
                                  .selectedRequestedToActionAccessRequestInstanceIds
                                  .length === 0
                              }
                              onClick={handleOpenRejectAccessRequestDialog}
                              sx={{ marginLeft: 'auto' }} // 右寄せで表示
                            >
                              {state
                                .selectedRequestedToActionAccessRequestInstanceIds
                                .length === 0
                                ? '却下する'
                                : `${state.selectedRequestedToActionAccessRequestInstanceIds.length}件を却下する`}
                            </Button>
                          </GridToolbarContainer>
                        </>
                      ),
                      NoRowsOverlay: (props) => (
                        <GridOverlay {...props}>
                          承認待ちの申請がありません。
                        </GridOverlay>
                      ),
                      NoResultsOverlay: (props) => (
                        <GridOverlay {...props}>
                          検索しましたが、見つかりませんでした。
                        </GridOverlay>
                      ),
                    }}
                    initialState={{
                      pinnedColumns: {
                        left: [GRID_CHECKBOX_SELECTION_FIELD],
                        right: [GRID_ACTIONS_COLUMN_TYPE],
                      },
                    }}
                    // WARNING: ダイアログ内にdataGridがあり、セルをクリックしたときにrowのfieldが取得できないバグ
                    // https://github.com/mui/mui-x/issues/7943
                    onCellClick={(_, e) => e.stopPropagation()}
                    sx={dataGridSxProps}
                  />
                </Box>
              </Box>
            )}
            {state.tabType === tabTypes.REQUESTED_TO && (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="end"
                p={2}
                style={{ background: 'white' }}
              >
                <Box
                  sx={{
                    height: '50vh',
                    width: '100%',
                  }}
                >
                  <DataGridPremium
                    loading={isLoading}
                    disableRowGrouping
                    disableColumnMenu
                    localeText={
                      jaJP.components.MuiDataGrid.defaultProps.localeText
                    }
                    rows={state.requestedToAccessRequestInstances}
                    columns={requestedToAccessRequestInstanceColumns}
                    hideFooter
                    components={{
                      Toolbar: (props) => (
                        <>
                          <Typography
                            variant="body1"
                            color="textSecondary"
                            sx={{ flexGrow: 1, textAlign: 'left' }} // 左寄せで表示
                          >
                            承認者として申請された履歴の一覧が確認できます。
                          </Typography>
                          <GridToolbarContainer
                            {...props}
                            sx={{ pt: 2, pb: 2 }}
                          >
                            <GridToolbarQuickFilter />
                          </GridToolbarContainer>
                        </>
                      ),
                      NoRowsOverlay: (props) => (
                        <GridOverlay {...props}>
                          申請された履歴がありません。
                        </GridOverlay>
                      ),
                      NoResultsOverlay: (props) => (
                        <GridOverlay {...props}>
                          検索しましたが、見つかりませんでした。
                        </GridOverlay>
                      ),
                    }}
                    initialState={{
                      pinnedColumns: {
                        right: [GRID_ACTIONS_COLUMN_TYPE],
                      },
                    }}
                    // WARNING: ダイアログ内にdataGridがあり、セルをクリックしたときにrowのfieldが取得できないバグ
                    // https://github.com/mui/mui-x/issues/7943
                    onCellClick={(_, e) => e.stopPropagation()}
                    sx={dataGridSxProps}
                  />
                </Box>
              </Box>
            )}
          </Box>
          {state.detailAccessRequestInstance !== undefined && (
            <Dialog
              fullWidth
              maxWidth="md"
              open={state.openDetailAccessRequestInstanceDialog}
              onClose={handleCloseDetailAccessRequestInstanceDialog}
            >
              <DialogTitle>申請内容の詳細</DialogTitle>
              <DialogContent>
                <Paper variant="outlined">
                  <Box sx={{ padding: '15px 24px' }}>
                    <Typography variant="h6">申請内容</Typography>
                    <Typography variant="caption" sx={{ minWidth: 250 }}>
                      申請の詳細情報が確認できます。
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '15px 24px',
                      flexDirection: { xs: 'column', sm: 'row' },
                    }}
                  >
                    <Typography variant="caption" sx={{ minWidth: 250 }}>
                      申請ID
                    </Typography>
                    <Typography>
                      {state.detailAccessRequestInstance.id}
                    </Typography>
                  </Box>
                  <Divider variant="middle" />
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '15px 24px',
                      flexDirection: { xs: 'column', sm: 'row' },
                    }}
                  >
                    <Typography variant="caption" sx={{ minWidth: 250 }}>
                      ステータス
                    </Typography>
                    <Typography
                      color={translateStatusColor(
                        state.detailAccessRequestInstance.status,
                      )}
                    >
                      {`${translateStatus(
                        state.detailAccessRequestInstance.status,
                      )} (${getApprovalsProgress(
                        state.detailAccessRequestInstance.approvals,
                      )})`}
                    </Typography>
                  </Box>
                  <Divider variant="middle" />
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '15px 24px',
                      flexDirection: { xs: 'column', sm: 'row' },
                    }}
                  >
                    <Typography variant="caption" sx={{ minWidth: 250 }}>
                      権限
                    </Typography>
                    {detailRequestedObject === undefined ? (
                      <Typography>---</Typography>
                    ) : (
                      <Card sx={{ boxShadow: 'none' }}>
                        <CardHeader
                          sx={{ p: 0 }}
                          avatar={
                            detailRequestedObject.logoImageUrl !== undefined ? (
                              <Avatar
                                alt={detailRequestedObject.logoImageUrl}
                                src={detailRequestedObject.logoImageUrl}
                                imgProps={{
                                  sx: {
                                    objectFit: 'contain',
                                    verticalAlign: 'text-bottom',
                                  },
                                }}
                              />
                            ) : null
                          }
                          title={detailRequestedObject.title}
                          titleTypographyProps={{ variant: 'caption' }}
                          subheader={detailRequestedObject.subheader}
                          subheaderTypographyProps={{
                            variant: 'body1',
                            color: 'text.primary',
                          }}
                        />
                      </Card>
                    )}
                  </Box>
                  <Divider variant="middle" />
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '15px 24px',
                      flexDirection: { xs: 'column', sm: 'row' },
                    }}
                  >
                    <Typography variant="caption" sx={{ minWidth: 250 }}>
                      申請者
                    </Typography>
                    <Typography>
                      {state.detailAccessRequestInstance.requested_by.name !==
                      ''
                        ? state.detailAccessRequestInstance.requested_by.name
                        : '---'}
                    </Typography>
                  </Box>
                  <Divider variant="middle" />
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '15px 24px',
                      flexDirection: { xs: 'column', sm: 'row' },
                    }}
                  >
                    <Typography variant="caption" sx={{ minWidth: 250 }}>
                      コメント
                    </Typography>
                    <Typography sx={{ whiteSpace: 'pre-wrap' }}>
                      {state.detailAccessRequestInstance.requested_by
                        .comment !== ''
                        ? state.detailAccessRequestInstance.requested_by.comment
                        : '---'}
                    </Typography>
                  </Box>
                  <Divider variant="middle" />
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '15px 24px',
                      flexDirection: { xs: 'column', sm: 'row' },
                    }}
                  >
                    <Typography variant="caption" sx={{ minWidth: 250 }}>
                      権限の有効期限
                    </Typography>
                    <Typography>
                      {state.detailAccessRequestInstance.expired_at !== null
                        ? formatDateTime(
                            state.detailAccessRequestInstance.expired_at,
                          )
                        : '---'}
                    </Typography>
                  </Box>
                  <Divider variant="middle" />
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '15px 24px',
                      flexDirection: { xs: 'column', sm: 'row' },
                    }}
                  >
                    <Typography variant="caption" sx={{ minWidth: 250 }}>
                      申請手続き開始日時
                    </Typography>
                    <Typography>
                      {state.detailAccessRequestInstance.started_at !== null
                        ? formatDateTime(
                            state.detailAccessRequestInstance.started_at,
                          )
                        : '---'}
                    </Typography>
                  </Box>
                  <Divider variant="middle" />
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '15px 24px',
                      flexDirection: { xs: 'column', sm: 'row' },
                    }}
                  >
                    <Typography variant="caption" sx={{ minWidth: 250 }}>
                      申請手続き終了日時
                    </Typography>
                    <Typography>
                      {state.detailAccessRequestInstance.finished_at !== null
                        ? formatDateTime(
                            state.detailAccessRequestInstance.finished_at,
                          )
                        : '---'}
                    </Typography>
                  </Box>
                </Paper>
                <Box marginBottom={2} />
                <Paper variant="outlined">
                  <Box sx={{ padding: '15px 24px' }}>
                    <Typography variant="h6">承認ステップ</Typography>
                    <Typography variant="caption" sx={{ minWidth: 250 }}>
                      各ステップごとの状況が確認できます。
                    </Typography>
                  </Box>
                  {state.detailAccessRequestInstance.approvals.map(
                    (approval, index) => (
                      <Paper key={index} variant="outlined" sx={{ m: 2 }}>
                        <Box sx={{ padding: '15px 24px' }}>
                          <Typography variant="h6">{`ステップ${
                            index + 1
                          }`}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            padding: '15px 24px',
                            flexDirection: { xs: 'column', sm: 'row' },
                          }}
                        >
                          <Typography variant="caption" sx={{ minWidth: 250 }}>
                            {approval.status === 'in_review' ||
                            approval.status === 'pending'
                              ? '承認者(候補)'
                              : '承認者'}
                          </Typography>
                          <Typography>
                            {approval.status === 'in_review' ||
                            approval.status === 'pending'
                              ? approval.approvers
                                  .map((a) => a.name)
                                  .join(' / ')
                              : approval.approved_by[0]?.name}
                          </Typography>
                        </Box>
                        <Divider variant="middle" />
                        <Box
                          sx={{
                            display: 'flex',
                            padding: '15px 24px',
                            flexDirection: { xs: 'column', sm: 'row' },
                          }}
                        >
                          <Typography variant="caption" sx={{ minWidth: 250 }}>
                            ステータス
                          </Typography>
                          <Typography
                            color={translateApprovalStatusColor(
                              approval.status,
                            )}
                          >
                            {`${translateApprovalStatus(approval.status)}`}
                          </Typography>
                        </Box>
                        <Divider variant="middle" />
                        <Box
                          sx={{
                            display: 'flex',
                            padding: '15px 24px',
                            flexDirection: { xs: 'column', sm: 'row' },
                          }}
                        >
                          <Typography variant="caption" sx={{ minWidth: 250 }}>
                            コメント
                          </Typography>
                          <Typography sx={{ whiteSpace: 'pre-wrap' }}>
                            {approval.approved_by[0]?.comment !== undefined &&
                            approval.approved_by[0]?.comment !== ''
                              ? approval.approved_by[0]?.comment
                              : '---'}
                          </Typography>
                        </Box>
                      </Paper>
                    ),
                  )}
                </Paper>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDetailAccessRequestInstanceDialog}>
                  閉じる
                </Button>
              </DialogActions>
            </Dialog>
          )}
          <Dialog
            fullWidth
            maxWidth="sm"
            open={state.openApproveAccesRequestDialog}
            onClose={handleCloseApproveAccessRequestDialog}
          >
            <DialogTitle>申請を承認する</DialogTitle>
            <DialogContent>
              <DialogContentText>
                必要に応じて、コメントを入力してください。
              </DialogContentText>
              <br />
              <TextField
                autoFocus
                margin="dense"
                id="comment"
                type="text"
                fullWidth
                multiline
                variant="outlined" // ここを変更して入力欄に枠を表示
                rows={4} // 複数行テキストのための行数指定
                value={state.comment}
                onChange={(e) =>
                  dispatch({
                    type: ACTION_TYPE.changeComment,
                    payload: { comment: e.target.value },
                  })
                }
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseApproveAccessRequestDialog}>
                キャンセル
              </Button>
              <Button onClick={handleClickApproveAccessRequest}>
                {isLoading ? <CircularProgress size={18} /> : '承認する'}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            fullWidth
            maxWidth="sm"
            open={state.openRejectAccessRequestDialog}
            onClose={handleCloseRejectAccessRequestDialog}
          >
            <DialogTitle>申請を却下する</DialogTitle>
            <DialogContent>
              <DialogContentText>
                必要に応じて、コメントを入力してください。
              </DialogContentText>
              <br />
              <TextField
                autoFocus
                margin="dense"
                id="comment"
                type="text"
                fullWidth
                multiline
                variant="outlined" // ここを変更して入力欄に枠を表示
                rows={4} // 複数行テキストのための行数指定
                value={state.comment}
                onChange={(e) =>
                  dispatch({
                    type: ACTION_TYPE.changeComment,
                    payload: { comment: e.target.value },
                  })
                }
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseRejectAccessRequestDialog}>
                キャンセル
              </Button>
              <Button onClick={handleClickRejectAccessRequest}>
                {isLoading ? <CircularProgress size={18} /> : '却下する'}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div className="pb-1 mt-8 absolute bottom-0" style={{ width: '100%' }}>
          <div style={{ height: 20 }} className="justify-center flex ">
            <span
              className="text-xs align-text-bottom"
              style={{ color: '#828282' }}
            >
              Onetap, Inc &copy {new Date().getFullYear()}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}
