import { fullUrl } from 'scripts/fullUrl'
import { RejectAccessRequestInstancesResponse } from 'types/ApiResponse'
import { axiosClient } from 'scripts/api'

export const putAccessRequestInstanceReject = async (params: {
  sessionToken: string
  id: string
  user_ip: string
  comment?: string | null
}): Promise<RejectAccessRequestInstancesResponse> => {
  const res = await axiosClient.put<RejectAccessRequestInstancesResponse>(
    `${fullUrl('api')}/access_request/access_request_instances/${
      params.id
    }/reject`,
    params,
  )
  return res.data
}
